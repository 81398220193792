import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import styled from 'styled-components'

import Heading from '../blocks/Heading.js'
import Subtitle from '../blocks/Subtitle.js'
import Track from './Track';

const CardContentWithPaddingBottomOnly = styled(CardContent)`
  && {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    min-height: 100px;
  }
`

const HorizontalPadding = styled.div`
  padding: 0px 16px;
`

class NowPlayingSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false,
      track: null
    }
  }

  // Load track
  async componentDidMount() {
    const { spotifyApi } = this.props

    const track = await spotifyApi.getMyCurrentPlayingTrack();

    this.setState({
      isLoaded: true,
      track: track && track.body && track.body.item
    })
  }

  render() {
    const { track, isLoaded } = this.state

    let content

    if(!isLoaded) {
      content = <CardContent>
                  <CircularProgress />
                </CardContent>
    } else if(track) {
      content = <Track track={track} />
    } else {
      content = <HorizontalPadding>
                  <Subtitle>
                    Not playing any songs right now.
                  </Subtitle>
                </HorizontalPadding>
    }

    return (
      <CardContentWithPaddingBottomOnly>
        <HorizontalPadding>
          <Heading>
            Now Playing
          </Heading>
        </HorizontalPadding>
        {content}
      </CardContentWithPaddingBottomOnly>
    );
  }
}

export default NowPlayingSection;
