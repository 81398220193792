import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'

class Row extends Component {
  render() {
    const { className, children, ...otherProps } = this.props;

    return(
      <Grid container spacing={16} className={className} {...otherProps}>
        { children }
      </Grid>
    )
  }
}

export default Row;
