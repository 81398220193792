import React, { Component } from 'react';
import MusicCard from '../components/MusicCard.js';
import nicci from '../nicci-music.jpg'


class NicciCard extends Component {
  render() {
    return (
      <MusicCard
        name={"Nicci's Music"}
        user={"niccihong"}
        picture={nicci}
        />
    );
  }
}

export default NicciCard
