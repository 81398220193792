import React, { Component } from 'react';
import MusicCard from '../components/MusicCard.js';
import finn from '../finn-music.jpg'

class NicciCard extends Component {
  render() {
    return (
      <MusicCard
        name={"Finn's Music"}
        user={'finn.woelm'}
        picture={finn}
        />
    );
  }
}

export default NicciCard
