import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone';
import CardSection from './CardSection.js';

class TimeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateTime: this.currentDateTime()
    };
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  currentDateTime() {
    return moment().tz(this.props.timezone);
  }

  tick() {
    this.setState({
      dateTime: this.currentDateTime()
    });
  }

  render() {
    const { dateTime } = this.state;

    return (
      <CardSection
        label={'Time'}
        text={dateTime.format('HH:mm')}
        subtext={
          <Fragment>
            {dateTime.format('dddd')}<br/>
            {dateTime.format('MMMM D')}
          </Fragment>
        } />
    );
  }
}

export default TimeSection;
