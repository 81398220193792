import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

class Subtitle extends Component {
  render() {
    const { className, children, ...otherProps } = this.props;

    return(
      <Typography variant='h6' color='textSecondary' className={className} {...otherProps}>
        { children }
      </Typography>
    )
  }
}

export default styled(Subtitle)`
  && {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1.2;
  }
`;
