import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components'

import TrackList from './TrackList';

const Background = styled.div`
  background: #354879;
`

class RecentTracks extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false,
      tracks: null
    }
  }

  // Load tracks
  async componentDidMount() {
    const { spotifyApi } = this.props

    const tracks = await spotifyApi.getMyRecentlyPlayedTracks({ limit: 50 });

    this.setState({
      isLoaded: true,
      tracks: tracks && tracks.body.items
    })
  }

  render() {
    const { tracks, isLoaded } = this.state

    return (
      <Background>
        <TrackList items={tracks} keyName="played_at" />

        {
          !isLoaded ? (
            <CardContent>
              <CircularProgress color='secondary' />
            </CardContent>
          ) : null }
      </Background>
    )
  }
}

export default RecentTracks;
