import React, { Fragment } from "react";
import Column from "../blocks/Column.js";
import BucketListCard from "../cards/BucketListCard.js";
import NicciCard from "../cards/NicciCard.js";
import NicciMusicCard from "../cards/NicciMusicCard.js";
import LoveCard from "../cards/LoveCard.js";
import FinnCard from "../cards/FinnCard.js";
import FinnMusicCard from "../cards/FinnMusicCard.js";
import WorkoutCard from "../cards/WorkoutCard";

function HomePage() {
  return (
    <Fragment>
      <Column xs={12} md={4}>
        <NicciCard />
      </Column>
      <Column xs={12} md={4}>
        <LoveCard />
      </Column>
      <Column xs={12} md={4}>
        <FinnCard />
      </Column>
      <Column xs={12} md={8}>
        <BucketListCard />
      </Column>
      <Column xs={12} md={4}>
        <WorkoutCard />
      </Column>
      <Column xs={12} md={6}>
        <NicciMusicCard />
      </Column>
      <Column xs={12} md={6}>
        <FinnMusicCard />
      </Column>
    </Fragment>
  );
}

export default HomePage;
