import React, { Component, Fragment } from 'react';

import styled from 'styled-components'

import CircularProgress from '@material-ui/core/CircularProgress';
import CardSection from './CardSection.js';

const InlineImage = styled.img`
  Vertical-align: middle;
`

class WeatherSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      temperature: null,
      description: null,
      iconId: null
    };
  }

  iconUrl() {
    return `https://openweathermap.org/img/w/${this.state.iconId}.png`;
  }

  componentDidMount() {
    fetch(`https://api.openweathermap.org/data/2.5/weather?q=${this.props.city},${this.props.country}&units=metric&appid=1a1f2797df746b05f40cb85108c64889`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            temperature: result.main.temp,
            description: result.weather[0].description,
            iconId: result.weather[0].icon
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, temperature, description } = this.state;

    var icon, label1, label2;

    if (error) {
      label1 = 'Error. Try again later.'
      label2 = error.message
    } else if (!isLoaded) {
      icon = <CircularProgress />
    } else {
      icon = <InlineImage alt={description} src={this.iconUrl()} />
      label1 = description
      label2 = <span>{Math.round(temperature)}&deg;C</span>
    }

    return (
      <CardSection
        label={'Weather'}
        text={icon}
        subtext={
          <Fragment>
            {label1}<br/>
            {label2}
          </Fragment>
        } />
    );
  }
}

export default WeatherSection;
