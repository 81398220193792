import React, { Component } from "react";
import PersonCard from "../components/PersonCard.js";
import nicci from "../nicci.jpg";

class NicciCard extends Component {
  render() {
    return (
      <PersonCard
        name={"Nicci"}
        picture={nicci}
        city={"Brussels"}
        country={"Belgium"}
        timezone={"Europe/Brussels"}
        dob={"19 Jan 1996"}
      />
    );
  }
}

export default NicciCard;
