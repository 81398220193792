import React, { Component } from 'react'
import moment from 'moment-timezone'
import CardSection from './CardSection.js'

class DayCountSection extends Component {
  get targetMoment() {
    const { to, from } = this.props

    return moment(to || from)
  }

  // return the moment at the beginning of the current day
  get currentDay() {
    const { timezone } = this.props

    let currentMoment = moment()

    // if timezone was passed, convert current moment to timezone
    if(timezone) {
      currentMoment = currentMoment.tz(timezone)
    }

    // convert current moment to current day
    const currentDay = moment(currentMoment.format('D MMM YYYY'))

    return currentDay
  }

  // return true if this is a countdown
  get isCountdown() {
    const { to } = this.props

    return Boolean(to && to.length)
  }

  // Pass is_inclusive if the end day should be day 1
  daysUntil(target, is_inclusive) {
    const daysUntilTarget = this.targetMoment.diff(this.currentDay, 'days') + (is_inclusive ? 1 : 0)

    return Math.max(daysUntilTarget, 0)
  }

  // Pass is_inclusive if the start day should be day 1
  daysSince(target, is_inclusive) {
    const daysSinceTarget = this.currentDay.diff(this.targetMoment, 'days') + (is_inclusive ? 1 : 0)

    return Math.max(daysSinceTarget, 0)
  }

  render() {
    const { to, from, label, inclusive } = this.props

    const dayCount = this.isCountdown ? this.daysUntil(to, inclusive) : this.daysSince(from, inclusive)

    return (
      <CardSection
        label={label}
        text={dayCount} />
    );
  }
}

export default DayCountSection
