import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

import styled from "styled-components";

import DayCountSection from "../components/DayCountSection.js";
import hearts from "../hearts.png";

const Avatar60 = styled(Avatar)`
  && {
    width: 60px;
    height: 60px;
  }
`;

class LoveCard extends Component {
  render() {
    return (
      <Card>
        <CardHeader
          avatar={<Avatar60 aria-label={"Love"} src={hearts} />}
          title={null}
          subheader={null}
        />
        <DayCountSection label={"Days to Reunion"} to={"5 Sep 2021"} />
        <DayCountSection label={"Days Since Last Kiss"} from={new Date()} />
        <DayCountSection
          label={"Days Together"}
          from={"11 Aug 2019"}
          inclusive
        />
      </Card>
    );
  }
}

export default LoveCard;
