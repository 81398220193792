import React, { Component, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardHeader from '@material-ui/core/CardHeader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { GET } from '../helpers/netlify';
import SpotifyWebApi from 'spotify-web-api-node';
import NowPlayingSection from './NowPlayingSection.js';
import FavoriteTracks from './FavoriteTracks';
import RecentTracks from './RecentTracks';
import Title from '../blocks/Title.js';

import styled from 'styled-components'

const Avatar60 = styled(Avatar)`
  && {
    width: 60px;
    height: 60px;
  }
`

const SpotifyTabs = styled(Tabs).attrs({
  variant: 'fullWidth',
  TabIndicatorProps: {
    style: {
      background: '#78ff00',
    }
  }
})`
  background: #1e3264;
  color: #78ff00;
`

const SpotifyTab = styled(Tab)`
  && {
    font-weight: bold;
  }
`

class MusicCard extends Component {
  constructor(props) {
    super(props);

    // create API instance
    const spotifyApi = new SpotifyWebApi({
      clientId: '60decbeb64b246cb8735368a13a74f04',
      redirectUri: 'https://www.nifi.love/callbacks/spotify'
    });

    this.state = {
      api: spotifyApi,
      isLoaded: false,
      activeTab: 0,
      accessToken: null,
    };
  }

  async componentDidMount() {
    const { accessToken } = await GET(`/get-spotify-access-token?user=${this.props.user}`)

    const { api: spotifyApi } = this.state

    if(accessToken) {
      spotifyApi.setAccessToken(accessToken);
    }

    this.setState({
      accessToken: accessToken,
      isLoaded: true,
    })
  }

  changeTab = (event, newActiveTab) => {
    this.setState({
      activeTab: newActiveTab
    });
  }

  connectWithSpotify = () => {
    var scopes = ['user-library-read', 'user-read-recently-played',
                  'user-read-currently-playing', 'user-read-playback-state']

    const { api: spotifyApi } = this.state

    const URL = spotifyApi.createAuthorizeURL(scopes)

    window.location.replace(URL)
  }

  render() {
    const { api } = this.state

    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar60 aria-label={this.props.name} src={this.props.picture} />
          }
          title={
            <Title>
              {this.props.name}
            </Title>
          }
        />
        {
          this.state.isLoaded ? (
            this.state.accessToken ? (
              <Fragment>
                <NowPlayingSection spotifyApi={api} />
                <SpotifyTabs
                  value={this.state.activeTab}
                  onChange={this.changeTab} >
                  <SpotifyTab label="Favorites" />
                  <SpotifyTab label="History" />
                </SpotifyTabs>
                {
                  this.state.activeTab === 0 ? (
                    <FavoriteTracks spotifyApi={api} />
                  ) : (
                    <RecentTracks spotifyApi={api} />
                  )}
              </Fragment>
            ) : (
              <CardContent>
                <Button
                  variant='raised'
                  color='primary'
                  onClick={this.connectWithSpotify}>
                  Connect with Spotify
                </Button>
              </CardContent>
            )
          ) : (
            <CardContent>
              <CircularProgress />
            </CardContent>
          )
        }
      </Card>
    );
  }
}

export default MusicCard;
