import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import { GET } from '../helpers/netlify';
import Column from '../blocks/Column.js';
import CardSection from '../components/CardSection';

class SpotifyCallbackPage extends Component {
  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const code = query.get("code")

    console.log(code)

    // Let server finish Spotify authentication
    const response = await GET(`/auth-with-spotify?code=${code}`)

    console.log(response)

    // Redirect home
    this.props.history.push('/')
  }

  render() {
    return (
      <Column xs={12}>
        <Card>
          <CardSection
            label="Connecting to Spotify"
            text="Please wait..."
            />
        </Card>
      </Column>
    );
  }
}

export default SpotifyCallbackPage
