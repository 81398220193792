import React, { Component, Fragment } from 'react';
import TimeAgo from 'react-timeago'

import Track from './Track';

class TrackList extends Component {
  render() {
    const { keyName, items } = this.props

    if(!items)
      return null

    return (
      <Fragment>
        {
          items.map(item => (
            <Track
              key={item[keyName]}
              track={item.track}
              meta={<TimeAgo date={item[keyName]} />}
              light />
          ))
        }
      </Fragment>
    );
  }
}

export default TrackList;
