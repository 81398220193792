import React from "react";
import CardContent from "@material-ui/core/CardContent";

import styled from "styled-components";

import Title from "../blocks/Title.js";
import Heading from "../blocks/Heading.js";
import Row from "../blocks/Row.js";
import Column from "../blocks/Column.js";
import Subtitle from "../blocks/Subtitle.js";

const CardContentWithoutPaddingTop = styled(CardContent)`
  && {
    padding-top: 0;
    min-height: 80px;
  }
`;

const TextWithoutSubtext = ({ text }) => <Title>{text}</Title>;

const TextWithSubtext = ({ text, subtext }) => (
  <Row alignItems="center">
    <Column>
      <TextWithoutSubtext text={text} />
    </Column>
    <Column>
      <Subtitle>{subtext}</Subtitle>
    </Column>
  </Row>
);

const CardSection = ({ label, content, text, subtext }) => (
  <CardContentWithoutPaddingTop>
    <Heading>{label}</Heading>
    {content}
    {subtext ? (
      <TextWithSubtext text={text} subtext={subtext} />
    ) : (
      <TextWithoutSubtext text={text} />
    )}
  </CardContentWithoutPaddingTop>
);

export default CardSection;
