import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

class VerticalAlign extends Component {
  render() {
    const { className, children, ...otherProps } = this.props;

    return(
      <Grid container alignItems='center' justify='center' className={className} {...otherProps}>
        { children }
      </Grid>
    )
  }
}

export default styled(VerticalAlign)`
  height: 100%;
`;
