import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  fixed: {
    margin: '0 auto',
    padding: 0,
    maxWidth: 1280,
    [theme.breakpoints.only('xs')]: {
      width: '90%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '85%'
    },
    [theme.breakpoints.up('md')]: {
      width: '70%'
    }
  }
})

class FixedWidthGrid extends Component {
  render() {
    const { classes, children, ...otherProps } = this.props;

    return(
      <Grid className={ classes.fixed } {...otherProps}>
        { children }
      </Grid>
    )
  }
}

export default withStyles(styles)(FixedWidthGrid)
