import React, { Component, Fragment } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Whatshot } from "@material-ui/icons";

import styled from "styled-components";

import sheetrock from "sheetrock";

import Title from "../blocks/Title.js";
import Subtitle from "../blocks/Subtitle.js";
import CardSection from "../components/CardSection";
import sneakers from "../sneakers.png";

const BUCKET_LIST_GOOGLE_SHEET =
  "https://docs.google.com/spreadsheets/d/1WuKTG9zd_AJApULm0lnnS-s2Vu50yVvohdgW2CyXZis/edit#gid=1579384740";

const Avatar60 = styled(Avatar)`
  && {
    width: 60px;
    height: 60px;
  }
`;

const CardContentWithoutPaddingTop = styled(CardContent)`
  && {
    padding-top: 0;
  }
`;

const Header = styled(CardHeader)`
  .card-action-area {
    align-self: center;
    margin: 0;
  }
`;

class BucketListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      points: null,
      targetPoints: null,
      nextReward: null,
    };
  }

  componentDidMount() {
    new Promise((resolve, reject) => {
      sheetrock({
        reset: true,
        url: BUCKET_LIST_GOOGLE_SHEET,
        // Hack: Make sure to query at least one column that contains numeric
        // data, so that points and nextReward are not erroneously interpreted
        // as header rows.
        // See: https://github.com/chriszarate/sheetrock/issues/136
        query: `SELECT J, K limit 3`,
        callback: (error, options, response) => {
          if (error) {
            reject(error);
          } else {
            const rows = response.rows;

            // return points/score
            resolve({
              points: parseInt(rows[0].cellsArray[0]),
              targetPoints: parseInt(rows[1].cellsArray[0]),
              nextReward: rows[2].cellsArray[0],
            });
          }
        },
      });
    }).then(
      ({ points, targetPoints, nextReward }) => {
        this.setState({
          isLoaded: true,
          points,
          targetPoints,
          nextReward,
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error);
        this.setState({
          isLoaded: true,
          error,
        });
      }
    );
  }

  render() {
    const { error, isLoaded, points, targetPoints, nextReward } = this.state;

    let content;

    if (error) {
      content = (
        <CardContentWithoutPaddingTop>
          Error. Try again later. {error.message}
        </CardContentWithoutPaddingTop>
      );
    } else if (!isLoaded) {
      content = (
        <CardContentWithoutPaddingTop>
          <CircularProgress />
        </CardContentWithoutPaddingTop>
      );
    } else {
      content = (
        <Fragment>
          <CardSection
            label="Current Points"
            text={points}
            subtext={
              <Fragment>
                {Array.from({ length: 10 }).map((_, index) => (
                  <Whatshot
                    key={index}
                    style={{
                      fill:
                        index + targetPoints - 10 < points
                          ? "#db3f1b"
                          : "#a4a4a4",
                      width: ".8em",
                      height: ".8em",
                    }}
                  />
                ))}
                <br />
                of {targetPoints}
              </Fragment>
            }
          />
          <CardSection
            label="Next Reward"
            content={
              <Fragment>
                <Subtitle variant="h6">{nextReward}</Subtitle>
                <Button
                  variant="outlined"
                  href={BUCKET_LIST_GOOGLE_SHEET}
                  target="_blank"
                  style={{ marginTop: 16 }}
                >
                  Manage
                </Button>
              </Fragment>
            }
          />
        </Fragment>
      );
    }

    return (
      <Card style={{ height: "100%" }}>
        <Header
          classes={{ action: "card-action-area" }}
          avatar={<Avatar60 aria-label={"Sneakers"} src={sneakers} />}
          title={<Title>Workout Tracker</Title>}
        />
        {content}
      </Card>
    );
  }
}

export default BucketListCard;
