import React, { Component } from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  display:block;
  width:100%; height:100%;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  filter: blur(5px);
  z-index: -1;
`;

class BlurredCoverImage extends Component {
  render() {
    return (
      <StyledImage
        alt='Website background'
        src={this.props.src} />
    );
  }
}

export default BlurredCoverImage
