import React, { Component, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import styled from 'styled-components'

import sheetrock from 'sheetrock'

import Title from '../blocks/Title.js';
import BucketListItem from '../components/BucketListItem.js';
import stars from '../stars.png'

const BUCKET_LIST_GOOGLE_SHEET = 'https://docs.google.com/spreadsheets/d/1WuKTG9zd_AJApULm0lnnS-s2Vu50yVvohdgW2CyXZis/edit#gid=0'

const Avatar60 = styled(Avatar)`
  && {
    width: 60px;
    height: 60px;
  }
`

const CardContentWithoutPaddingTop = styled(CardContent)`
  && {
    padding-top: 0;
  }
`

const BucketListHeader = styled(CardHeader)`
  .card-action-area {
    align-self: center;
    margin: 0;
  }
`

class BucketListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
  }

  componentDidMount() {
    new Promise((resolve, reject) => {
      sheetrock({
        reset: true,
        url: BUCKET_LIST_GOOGLE_SHEET,
        query: `SELECT A, B WHERE A<>''`,
        callback: (error, options, response) => {
          if (error) {
            reject(error)
          } else {
            const rows = response.rows

            // remove header
            rows.shift()

            // return rows
            resolve(rows)
          }
        }
      })
    }).then(
      (rows) => {
        this.setState({
          isLoaded: true,
          items: rows.map(row => ({
            title: row.cellsArray[0],
            isComplete: (row.cellsArray[1] === 'TRUE')
          }))
        })
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error);
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  render() {
    const { error, isLoaded, items } = this.state;

    let content

    if (error) {
      content = 'Error. Try again later. ' + error.message
    } else if (!isLoaded) {
      content = <CircularProgress />
    } else {
      content = items.filter(item => !item.isComplete).map(item => (
        <BucketListItem
          key={item.title}
          title={item.title}
          isComplete={item.isComplete} />
      ))
    }

    const addItemLinkProps = {
      target: '_blank',
      href: BUCKET_LIST_GOOGLE_SHEET,
      component: 'a'
    }

    return (
      <Card>
        <BucketListHeader
          classes={{ action: 'card-action-area' }}
          avatar={
            <Avatar60 aria-label={'Stars'} src={stars} />
          }
          title={
            <Title>
              Bucket List
            </Title>
          }
          action={
            <Fragment>
              <Hidden smUp>
                <IconButton aria-label="Add Item" {...addItemLinkProps}>
                  <AddIcon />
                </IconButton>
              </Hidden>
              <Hidden xsDown>
                <Button variant="outlined" {...addItemLinkProps}>
                  <AddIcon />
                  Add item
                </Button>
              </Hidden>
            </Fragment>
          }
        />
        <CardContentWithoutPaddingTop>
          {content}
        </CardContentWithoutPaddingTop>
      </Card>
    );
  }
}

export default BucketListCard;
