import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import styled from 'styled-components'

import TrackList from './TrackList';

const Background = styled.div`
  background: #354879;
`

const SpotifyButton = styled(Button)`
  && {
    color: #78ff00;
  }
`

class FavoriteTracks extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      tracks: []
    }
  }

  // Load tracks
  async componentDidMount() {
    this.loadMoreTracks()
  }

  // load more tracks
  loadMoreTracks = async () => {
    this.setState({
      isLoading: true
    })

    const { spotifyApi } = this.props
    const { tracks: loadedTracks } = this.state

    const response = await spotifyApi.getMySavedTracks({ limit: 50, offset: loadedTracks.length })
    const moreTracks = (response && response.body.items) || []

    this.setState({
      tracks: loadedTracks.concat(moreTracks),
      hasMoreTracks: response.body.next,
      isLoading: false
    })
  }

  render() {
    const { tracks, isLoading, hasMoreTracks } = this.state

    return (
      <Background>
        <TrackList items={tracks} keyName="added_at" />

        {
          isLoading ? (
            <CardContent>
              <CircularProgress color='secondary' />
            </CardContent>
          ) : null
        }

        {
          !isLoading && hasMoreTracks ? (
            <CardContent>
              <SpotifyButton onClick={this.loadMoreTracks}>
                Load More
              </SpotifyButton>
            </CardContent>
          ) : null
        }
      </Background>
    )
  }
}

export default FavoriteTracks;
