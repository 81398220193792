import React, { Component } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import styled from 'styled-components';
import netlifyIdentity from 'netlify-identity-widget';
import FixedWidthGrid from './blocks/FixedWidthGrid.js';
import Row from './blocks/Row.js';
import VerticalAlign from './blocks/VerticalAlign.js';
import BlurredCoverImage from './components/BlurredCoverImage.js';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SpotifyCallbackPage from './pages/SpotifyCallbackPage';

const RowWithPadding = styled(Row)`
  && {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`

const PublicRoute = (props) => {
  const user = netlifyIdentity.currentUser();

  console.log(netlifyIdentity)

  return user ? <Redirect to="/" /> : <Route {...props} />;
};

const PrivateRoute = (props) => {
  const user = netlifyIdentity.currentUser();

  return user ? <Route {...props} /> : <Redirect to={`/login${window.location.hash}`} />;
};

class App extends Component {
  constructor() {
    super()

    netlifyIdentity.on('login', user => this.forceUpdate());
  }

  render() {
    return (
      <BrowserRouter>
        <BlurredCoverImage src='https://source.unsplash.com/collection/2183975/1280x720/daily'/>
        <VerticalAlign>
          <FixedWidthGrid>
            <RowWithPadding>
              <Switch>
                <PrivateRoute
                  exact path="/"
                  component={HomePage}>
                </PrivateRoute>
                <PrivateRoute
                  path="/callbacks/spotify"
                  component={SpotifyCallbackPage}>
                </PrivateRoute>
                <PublicRoute
                  path="/login"
                  component={LoginPage}>
                </PublicRoute>
              </Switch>
            </RowWithPadding>
          </FixedWidthGrid>
        </VerticalAlign>
      </BrowserRouter>
    );
  }
}

export default App;
