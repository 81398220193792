import React, { Component } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Column from '../blocks/Column.js';
import Title from '../blocks/Title.js';


class LoginPage extends Component {
  constructor() {
    super()

    this.handleLogIn = this.handleLogIn.bind(this)
  }

  handleLogIn() {
    // Prompt login with Google
    netlifyIdentity.store.externalLogin('google')
  }

  render() {
    return (
      <Column xs={12}>
        <Card>
          <CardHeader
            title={
              <Title>
                Protected Website
              </Title>
            }
          />
          <CardContent>
            <Button
              variant='raised'
              color='primary'
              onClick={this.handleLogIn}>
                Sign in with Google
            </Button>
          </CardContent>
        </Card>
      </Column>
    );
  }
}

export default LoginPage
