import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

class Title extends Component {
  render() {
    const { className, children, ...otherProps } = this.props;

    return(
      <Typography variant='h5' className={className} {...otherProps}>
        { children }
      </Typography>
    )
  }
}

export default styled(Title)`
  && {
    font-size: 2rem;
  }
`;
