import React from 'react'
import Typography from '@material-ui/core/Typography';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import styled from 'styled-components'

const Item = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    color: rgba(0,0,0,.3);
  }
`

const BucketListItem = ({ title, isComplete }) => (
  <Item>
    {isComplete ? (
      <CheckBoxIcon style={{color: 'rgb(8, 159, 30)'}} />
    ) : (
      <CheckBoxOutlineBlankIcon color='secondary' />
    )}
    <Typography
      variant='body1'
      style={{textDecorationLine: `${isComplete ? 'line-through' : 'none'}`}}>
      {title}
    </Typography>
  </Item>
)

export default BucketListItem
