import React, { Component } from "react";
import PersonCard from "../components/PersonCard.js";
import finn from "../finn.jpg";

class FinnCard extends Component {
  render() {
    return (
      <PersonCard
        name={"Finn"}
        picture={finn}
        city={"Paris"}
        country={"France"}
        timezone={"Europe/Paris"}
        dob={"3 Dec 1992"}
      />
    );
  }
}

export default FinnCard;
