import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import DayCountSection from './DayCountSection.js';
import TimeSection from './TimeSection.js';
import WeatherSection from './WeatherSection.js';
import Title from '../blocks/Title.js';
import Subtitle from '../blocks/Subtitle.js';

import styled from 'styled-components'

const Avatar60 = styled(Avatar)`
  && {
    width: 60px;
    height: 60px;
  }
`

class PersonCard extends Component {
  render() {
    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar60 aria-label={this.props.name} src={this.props.picture} />
          }
          title={
            <Title>
              {this.props.name}
            </Title>
          }
          subheader={
            <Subtitle>
              {this.props.city}
            </Subtitle>
          }
        />
        <TimeSection
          timezone={this.props.timezone} />
        <WeatherSection
          city={this.props.city}
          country={this.props.country} />
        <DayCountSection
          label={'Day of Life'}
          from={this.props.dob}
          timezone={this.props.timezone} />
      </Card>
    );
  }
}

export default PersonCard;
