import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

class Column extends Component {
  static defaultProps = {
    align: "left",
  }

  render() {
    const { className, children, ...otherProps } = this.props;

    return(
      <Grid item className={className} {...otherProps}>
        { children }
      </Grid>
    )
  }
}

export default styled(Column)`
  text-align: ${props => props.align};
`
