import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

class Heading extends Component {
  render() {
    const { className, children, ...otherProps } = this.props;

    return(
      <Typography variant='h6' className={className} {...otherProps}>
        <span>
          { children }
        </span>
      </Typography>
    )
  }
}

export default styled(Heading)`
  && {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
  }

  &&:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    content: ' ';
    position: relative;
    top: 14px;
    margin-right: 15px;
  }

  span {
    background: white;
    padding-right: 15px;
    position: relative;
  }
`;
