import React from 'react'
import { ButtonBase, Typography } from '@material-ui/core';

import styled from 'styled-components'

import Title from '../blocks/Title.js'
import Row from '../blocks/Row.js'
import Column from '../blocks/Column.js'
import Subtitle from '../blocks/Subtitle.js'

const Item = styled(ButtonBase).attrs({
  component: 'a'
})`

  /* match padding of card content */
  && {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }

  &:hover {
    background: #0000001f;
  }

  img {
    vertical-align: middle;
    border-radius: 100%;
  }
`

const RowNoWrap = styled(Row)`
  && {
    flex-wrap: nowrap;
  }
`

const ColumnAlignStart = styled(Column)`
  && {
    align-self: start;
  }
`

const ColumnWithGrow = styled(Column)`
  && {
    flex-grow: 1;
  }
`

const Emphasis = styled.span`
  font-weight: 400;
  color: ${props => props.light ? 'white' : 'black'};
`

const SubtitleDefaultCase = styled(Subtitle)`
  && {
    color: ${props => props.light ? 'white' : 'black'};
    text-transform: none;
  }
`

const MetaText = styled(Typography).attrs({
  variant: 'caption'
})`
  && {
    color: ${props => props.light ? '#ffffffab' : 'black'};
  }
`

const Track = ({ light, meta, track: { external_urls: { spotify: url }, album: { images }, name, artists } }) => (
  <Item href={url} target="_blank" title="Open track">
    <RowNoWrap alignItems='center'>
      <ColumnAlignStart>
        <Title>
          <img
            alt="Track cover"
            src={images[2].url}
            width={50}
            height={50} />
        </Title>
      </ColumnAlignStart>
      <ColumnWithGrow>
        <SubtitleDefaultCase light={light}>
          <Emphasis light={light}>
            {name}
          </Emphasis>
          <br/>
          {artists.map(a => a.name).join(", ")}
        </SubtitleDefaultCase>
      </ColumnWithGrow>
      {
        meta ? (
          <Column>
            <MetaText light={light} align='center'>
              {meta}
            </MetaText>
          </Column>
        ) : null
      }
    </RowNoWrap>
  </Item>
)

export default Track
